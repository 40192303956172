.blog {
	padding: 40px 0 160px;
	position: relative;
	@include r(1023) {
		padding: 40px 0 110px; }
	@include r(767) {
		padding: 40px 0 70px; }

	&.is-last {
		padding: 60px 0 220px;
		@include r(1023) {
			padding: 60px 0 150px; } }

	.triangle {
		max-width: 120px;
		&.is-first {
			top: 150px; }
		&.is-second {
			top: -150px; }
		&.is-third {
			max-width: 200px;
			top: 0; } }




	&__content {
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		flex-wrap: wrap;
		margin: -10px;

		&.is-reverse {
			flex-direction: row-reverse;
			justify-content: flex-end;

			.blog__block {
				order: 1;
				&.is-height {
					order: 0; } } }

		.is-height {
			max-width: 100%;
			order: 0;
			.blog__wrapper {
				padding: 0 0 10px; }
			.blog__title {
				padding: 30px 50px 20px; } }

		@supports(display: grid) {
			margin: 0;
			flex-wrap: nowrap;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
			gap: 20px;
			@include r(1023) {
				grid-template-columns: 1fr; }

			&.is-reverse {
				.blog__block {
					order: unset;
					&.is-height {
						order: unset; } }
				.is-height {
					grid-column: 2/3;
					@include r(1023) {
						grid-column: 1/2; } } }

			.is-height {
				.blog__wrapper {
					padding: 0; } } } }


	&__block {
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		max-width: 50%;
		width: 100%;
		padding: 10px;

		@supports(display: grid) {
			max-width: 100%;
			padding: 0; }

		&.is-height {
			padding: 10px 10px 10px;

			@supports(display: grid) {
				grid-row: 1/4;
				padding: 0; }

			.blog__img {
				flex-grow: 2; }

			.blog__title {
				padding: 40px;
				flex-grow: 1;
				min-height: 4.5em;
				@include r(1023) {
					padding: 20px;
					min-height: 3.5em; }

				h3 {
					font-size: 36px;
					@include r(1023) {
						font-size: 24px; } } } } }

	&__wrapper {
		height: 100%;
		flex-direction: column;
		align-items: stretch;
		justify-content: flex-start;
		background-color: $white;
		padding: 30px 50px 50px;
		@include r(1023) {
			padding: 30px; } }

	&__img {
		position: relative;
		z-index: 1;
		height: 300px;

		@supports(display: grid) {
			height: auto;
			@include r(1023) {
				height: 250px; } }

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: relative;
			z-index: 0; }

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			pointer-events: none;
			background-color: rgba($black, .3); }

		.blog__time {
			position: absolute;
			z-index: 2;
			left: 50px;
			top: 30px;
			color: $white; } }

	&__title {
		font-size: 24px;
		display: flex;
		align-items: center; }


	&__time {
		margin: 0 0 35px;
		font-size: 18px;
		@include bold;
		color: #DADADA; } }
