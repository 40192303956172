.hero {
	position: relative;

	&__container {
		position: relative;
		background-position: center center; }

	&__title {
		padding: 200px 0;
		@include r(1365) {
			padding: 120px 0; }

		@include r(1279) {
			padding: 100px 0; }

		> * {
			color: $white; } }


	&__slide {
		min-height: 100vh;
		padding-bottom: 100px;
		display: flex!important;
		align-items: center;
		justify-content: center;
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		@include r(1365) {
			min-height: 700px; }
		@include r(767) {
			min-height: 500px; }

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: rgba($black, .4); }
		.container {
			align-self: center;
			color: $white;
			z-index: 2; }

		&__content {
			max-width: 960px;
			@include r(1023) {
				padding: 30px 15% 20px 0; }
			@include r(767) {
				padding: 30px 0; }
			@include b(440) {
				padding: 30px 0; } }

		&__title,
		&__sub-title {
			h2,
			p {
				color: inherit; } }

		&__title {
			margin-bottom: 35px;
			@include r(1023) {
				margin-bottom: 30px; }
			h2 {
				font-size: 80px;
				@include r(1023) {
					font-size: 64px; }
				@include r(767) {
					font-size: 36px; } } }

		&__sub-title {
			max-width: 800px;
			margin-bottom: 100px;
			@include r(767) {
				margin-bottom: 60px; }
			p {
				font-size: 24px;
				@include r(1023) {
					font-size: 18px; }
				@include r(767) {
					font-size: 14px; } } }

		&__button {
			display: inline-block;
			margin-bottom: -30px;

			.site-button {
				margin: 0 30px 30px 0;
				&:last-child {
					margin: 0; } } } }

	&__nav {
		width: 100%;
		pointer-events: none;
		position: absolute;
		bottom: 40px;
		left: 0;
		@include r(767) {
			bottom: 30px; }
		@include b(440) {
			bottom: 10px; }
		.arrow {
			path {
				fill: $white; }
			.stroke {
				stroke: $white; } } } }
