.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 20;
	display: none;

	&__close {
		position: absolute;
		top: 0;
		left: calc(100% + 10px);
		width: 30px;
		height: 30px;
		display: block;
		cursor: pointer;
		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $white;
			top: 50%;
			left: 0; }

		&::before {
			transform: rotate(45deg); }

		&::after {
			transform: rotate(135deg); } }

	&__layer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 1;
		background-color: rgba($black, .8);
		display: block; }

	&__container {
		z-index: 2;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-width: calc(90% - 60px);
		width: 100%;
		height: 90%;
		align-items: center;
		justify-content: center; }

	.recommend {
		&__content {
			img {
				max-width: 100%;
				margin: 0 auto; } } } }

